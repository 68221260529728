.photo-elements__capture {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
    aspect-ratio: 1/1;
    grid-column-start: 1;
    grid-column-end: 3;
    cursor: pointer;
    background-color: black;
}