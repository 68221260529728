.pop-up__container {
    position: fixed;
    width: 100%;
    max-width: 430px;
    display: flex;
    flex-direction: column;
    padding: 34px 36px 37px 36px;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    z-index: 1;
    box-shadow: 0px 0px 25px 0px #00000026;
}

@media (max-width: 530px) {
    .pop-up__container {
        width: 88.125%;
        max-width: 400px;
        padding: 25px 22px 25px 22px;
    }
    
}