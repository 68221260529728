.photo-elements__item {
    position: relative;
    display: grid;
    grid-template-columns: 1fr fit-content(42px);
    gap: 25px 0;
    grid-template-rows: auto;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    border-radius: 10px;
}