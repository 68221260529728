.pop-up__title_type_info-tooltip {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 25px;
}

@media (max-width: 530px) {
    .pop-up__title_type_info-tooltip {
        margin-top: 40px;
        font-size: 20px;
        line-height: 24px;  
    } 
}