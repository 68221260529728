.photo-elements__like-button {
    width: 22px;
    height: 19px;
    border: none;
    background-color: #ffffff;
    background-image: url(../../../images/like.svg);
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    margin: 0;
    cursor: pointer;
    
}

.photo-elements__like-button:hover {
        opacity: 0.5;
    }
