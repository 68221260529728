.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    min-height: 74px;
    margin: 0 auto;
    margin-top: 45px;
    border-bottom: 1px solid #545454b3;
    box-sizing: border-box;
}

@media (max-width:350px) {
    .header {
        min-height: 60px;
        margin-top: 24px;
    }
}