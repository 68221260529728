.profile__info {
    display: flex;
    align-items: center;
    gap: 30px;
}

@media (max-width: 620px) {
    .profile__info {
        flex-direction: column;
        gap: 26px;
    }
}