.page {
    max-width: 880px;
    width: 90%;
    background-color: #000000;
    color: #ffffff;
    margin: 0 auto;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%; 
}

@media (max-width: 320px) {
    .page {
        width: 100%;
    }
}
