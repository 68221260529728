.pop-up {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100%;
    background-color: #00000080;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.4s, opacity 0.4s linear;
}