.pop-up__input {
    padding: 0;
    padding-bottom: 9px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    border: none;
    border-bottom:1px solid #00000033;
}

.pop-up__input:focus {
	outline: none;
}