.pop-up__status-icon {
    margin: 0 auto;
    margin-top: 30px;
    width: 120px;
    height: 120px;
}


@media (max-width: 530px) {
    .pop-up__status-icon {
        margin-top: 25px;
    } 
}