.pop-up__button {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    padding: 0;
    margin-top: 18px;
    background-color: #000000;
    color: #ffffff;
    border: none;
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}

.pop-up__button:hover {
    opacity: 0.8;
}

@media (max-width: 530px) {
    .pop-up__button {
        height: 46px;
        margin-top: 15px;
        font-size: 14px;
        line-height: 17px;
    } 
}