.pop-up__title {
    margin: 0;
    margin-bottom: 48px;
    color: #000000;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
}

@media (max-width: 530px) {
    .pop-up__title {
        margin-bottom: 75px;
        font-size: 18px;
        line-height: 22px;
    }
    
}