.footer__copyright {
    margin: 0;
    color: #545454;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
}

@media (max-width: 620px) {
    .footer__copyright {
        font-size: 14px;
        line-height: 17px;
    }
}