.photo-elements__title {
    max-width: 100%;
    color: black;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin: 0;
    margin-left: 21px;
    margin-bottom: 25px;
}


@media (max-width: 900px) {
    .photo-elements__title {
        font-size: 20px;
        line-height: 23px;
    }
}

@media (max-width: 730px) {
    .photo-elements__title {
        font-size: 24px;
        line-height: 29px;
    } 
}