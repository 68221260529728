.sign__button_disabled {
    background-color: #FFFFFF;
    color: rgb(0, 0, 0,0.2);
    border: 1px solid rgb(0, 0, 0,0.2);
    cursor: auto;
}

.sign__button:hover {
    background-color: #FFFFFF;
    color: rgb(0, 0, 0,0.2);
    border: 1px solid rgb(0, 0, 0,0.2);
}