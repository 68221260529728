.profile__avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: 0 auto;
    cursor: pointer;
    object-fit: cover;
}

.profile__avatar:hover {
    opacity: 0.2;
}