.sign__title {
    margin: 0 auto;
    margin-bottom: 50px;
    color: white;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
}

@media (max-width: 530px) {
    .sign__title {
        margin-bottom: 40px;
        font-size: 20px;
        line-height: 24px;
    }
    
}