.profile__button-cards {
    width: 150px;
    height: 50px;
    background-color: black;
    border: 2px solid #ffffff;
    background-image: url(../../../images/button.png);
    background-size: 22px 22px;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    padding: 0;
    margin-left: 10px;
    cursor: pointer;
    border-radius:  2px;
}

.profile__button-cards:hover {
    opacity: 0.6;
}

@media (max-width: 730px) {
    .profile__button-cards {
        margin: 0;
        margin-top: 20px;
    }
}

@media (max-width: 620px) {
    .profile__button-cards {
        width: 100%;
        margin-top: 33px;
        background-size: 16px 16px;
        margin-left: 0;
        
    }
}