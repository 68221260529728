.header__logo {
    width: 142px;
    height: 33px;
    background-image: url(../../../images/mesto-logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
}
@media (max-width: 620px) {
    .header__logo {

        margin-bottom: 30px;
    }
}

@media (max-width: 350px) {
    .header__logo {
        width: 103.74px;
        height: 24.4px;
        margin-left: 30px;
    }
}

