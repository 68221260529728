.profile__firstname {
    max-width: 400px;
    font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 48px;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 730px) {
    .profile__firstname {
        font-size: 27px;
        line-height: 33px;
    }
}

@media (max-width: 620px) {
    .profile__firstname {
        text-align: center;
        margin-left: 24px;
    }
}