.profile {
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 730px) {
    .profile {
        margin-top: 42px;
        flex-direction: column;
    }
}