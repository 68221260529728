.error {
    display: flex;
    align-items: center;
    margin: 0;
    color: red;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    min-height:30px;
}