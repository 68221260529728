.profile__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

@media (max-width: 620px) {
    .profile__list {
        margin: 0 auto;
    }
}