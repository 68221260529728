.sign__button {
    width: 100%;
    height: 50px;
    margin: 0 auto;
    padding: 0;
    margin-top: 186px;
    background-color: #ffffff;
    color: #000000;
    border: none;
    border-radius: 2px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
}

.sign__button:hover {
    opacity: 0.85;
}

@media (max-width: 530px) {
    .sign__button {
        height: 46px;
        margin-top: 145px;
        font-size: 16px;
        line-height: 19px;
        height: 46px;
    } 
}